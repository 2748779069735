<template>
  <div class="content">
    <div class="logo" v-if="webLogo !== ''"><img class="logoImg" :src="webLogo" alt=""></div>
    <!-- <div class="logo"><img class="logoImg" src="../assets/logo.png" alt=""></div> -->


    <div></div>
    <div class="userOperationArea">
      <!-- 客服 -->
      <div class="customerService">
        <el-popover placement="bottom-end" width="200" trigger="hover">
          <div class="customerServiceBox">
            <div class="contactUs">联系我们</div>
            <div class="contactUsImg"><img :src="wxLogo" alt=""></div>
            <div>微信扫码联系官方快速反馈问题</div>
            <div class="assist">
              <div class="left">帮助文档</div>
              <div class="right"><i class=" el-icon-arrow-right"></i> </div>
            </div>
            <div class="cooperation">
              <div class="left">合作咨询</div>
              <div class="right"><i class=" el-icon-arrow-right"></i> </div>
            </div>
          </div>

          <div slot="reference">
            <img src="../assets/kefu.png" alt="">
          </div>
        </el-popover>
      </div>
      <!-- 小程序体验 -->
      <div class="activationCode">
        <el-popover placement="bottom-end" width="200" trigger="hover">
          <div class="experience">
            <div class=""><img class="wechatID" :src="wxExperience" alt=""></div>
            <div>微信扫码体验</div>
          </div>
          <div class="activeBox" slot="reference">
            <div class="img"> <img class="image" src="../assets/duihuan.png" alt=""></div>
            <div>小程序体验</div>
          </div>
        </el-popover>
      </div>
      <!-- 点数充值 -->
      <div class="openMembership" @click="pointPurchaseShow = true">

        <div class="img"> <img class="image" src="../assets/huiyuan.png" alt=""></div>
        <div>点数充值</div>
      </div>
      <!-- 个人信息 -->
      <div class="userInfo">
        <div class="avatar" v-if="!userInfoIss"> <img :src="avatar" alt="" @click="userShow = true"></div>
        <div class="UserAvatar" v-if="userInfoIss" @mouseenter="userEnter()">
          <!-- el-popper -->
          <el-popover placement="bottom-end" width="300" trigger="hover">
            <div class="userBox">
              <!-- 个人信息 -->
              <div class="user_avatar_name_phone">
                <div class="user_avatar" @click="editUserInfor">
                  <img v-if="userInfo.avatar" @click="editUserInfor" :src="userInfo.avatar" alt="">
                  <img v-if="!userInfo.avatar" src="../assets/moren.png" alt="">
                </div>
                <div class="user_name_phone">
                  <div class="user_name" @click="editUserInfor">
                    <div>{{ userInfo.store_name }}</div>
                    <div><img src="../assets/zuopin.png" alt=""></div>
                  </div>
                  <div class="user_phone"></div>
                  {{ userInfo.username }}
                </div>
              </div>
              <!-- 算力点 -->
              <div class="balance">
                <div class="left">剩余算力点数: {{ userInfo.price }}</div>
                <div class="right">购买</div>
              </div>
              <!-- 购买记录 -->
              <div class="purchaseRecord" @click="consumptionRecordShow = true">
                <div class="left">消费记录</div>
                <div class="right"><i class=" el-icon-arrow-right"></i> </div>
              </div>
              <!-- 退出登录 -->
              <div class="quit" @click="quit">退出登录</div>
            </div>
            <img slot="reference" v-if="userInfo.avatar" :src="userInfo.avatar" alt="">
            <img slot="reference" v-if="!userInfo.avatar" src="../assets/moren.png" alt="">
          </el-popover>
        </div>

        <div>
          <Login :userShow="userShow" @userAvatar="userAvatar" @getUserShow="getUserShow" @userInfoIs="userInfoIs"
            :show_close="show_close">
          </Login>
        </div>


      </div>



      <!-- 更多操作 -->
      <div class="more">
        <div class="left">
          <img class="image" src="../assets/top_left.png" alt="">
        </div>
        <div class="middle">|</div>
        <div class="right">
          <img class="image" src="../assets/top_right.png" alt="">
        </div>
      </div>
    </div>


    <!-- 点数充值弹框 -->
    <el-dialog custom-class="pointPurchaseShow" :visible.sync="pointPurchaseShow" width="350px">
      <PointPurchase :pointPurchaseShow="pointPurchaseShow" />
    </el-dialog>


    <!-- 修改用户信息 -->
    <el-dialog custom-class="editUserInforShow" :visible.sync="editUserInforShow" width="350px">
      <editUserInfo :item="editInfo" @avatarPath="avatarPath" @userAvatar="userAvatar"
        @editUserInfoShow="editUserInfoShow" />
    </el-dialog>


    <!-- 消费记录 -->
    <el-dialog title="积分消费记录" custom-class="consumptionRecordShow" :visible.sync="consumptionRecordShow" width="600px">
      <div class="consumpContent" id="consumpContent">
        <ConsumptionRecord ref='chageConsumption' :page='consumptionPage' @integralUsageTotal="integralUsageTotal" />
      </div>
      <div class="paging">
        <el-pagination small layout="prev, pager, next" :total="consumptionTotal" @current-change="handlePageChange">
        </el-pagination>
      </div>
    </el-dialog>



  </div>
</template>

<script>
import { throttle } from '../common/js/utils'
import Login from './LogIn/LogIn.vue'
import PointPurchase from '../views/PointPurchase.vue'
import editUserInfo from '../views/editUserInfo.vue'
import ConsumptionRecord from '../views/ConsumptionRecord.vue'//消费记录

import { getUserInfo } from '../../api/api'
export default {

  name: 'Header',
  components: {
    Login,
    PointPurchase,
    editUserInfo,
    ConsumptionRecord

  },
  data() {
    return {
      webLogo: '',//LOGO
      wxLogo: '',//客服LOGO
      wxExperience: '',//微信体验Logo
      avatar: require('../assets/user.png'),//未登录头像
      userInfo: {},//用户信息

      show_close: false,//登录弹框关闭按钮
      userShow: false,//登录弹框
      activationInput: '',
      codeInput: '',
      time: null,
      userInfoIss: false,//判断是否登录

      pointPurchaseShow: false,//充值弹框
      editUserInforShow: false,//修改用户信息弹框
      consumptionRecordShow: false,//消费记录弹框
      consumptionTotal: 0,//积分使用总条数
      consumptionPage: 1,//积分使用分页


      fullscreenLoading: false,//加载

      editInfo: {
        avatar: '',
        name: ''
      },
    }
  },
  methods: {
    // 积分总条数
    integralUsageTotal(e) {
      // console.log(e)
      this.consumptionTotal = e
    },
    // 积分使用分页
    handlePageChange(e) {
      // console.log(e)
      let that = this
      const loading = that.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });
      that.consumptionPage = e
      that.time = setTimeout(function () {
        that.$refs.chageConsumption.getIntegralRecord()
        document.getElementById("consumpContent").scrollTop = 0
        that.time = null;
        loading.close();
      }, 1000)
    },


    // 修改用户信息
    editUserInfor() {
      this.editUserInforShow = true
    },
    // 这是用户上传头像后,OSS返回的图片的地址
    avatarPath(e) {
      this.editInfo.avatar = e
    },
    editUserInfoShow(e) {
      this.editUserInforShow = e
    },


    getUserShow(e) {
      this.userShow = e
    },
    userAvatar(e) {
      this.userInfo = JSON.parse(e)
      // console.log(JSON.parse(e))
      this.$forceUpdate();
    },
    userEnter() {
      if(!localStorage.getItem('token')) return
      let that = this
      if (that.time) {
        clearTimeout(that.time)
      }
      that.time = setTimeout(function () {
        getUserInfo().then(res => {
          localStorage.setItem('userInfo', JSON.stringify(res.data.data));
          that.userInfo = res.data.data
        })
        that.$forceUpdate()
        that.time = undefined;
      }, 1000)
    },
    userInfoIs(e) {
      this.userInfoIss = e
    },

    //节流
    // ?:throttle(function(){
    // }),

    // 防抖
    // ?: function () {
    //   let that = this
    //   if (that.time) {
    //     clearTimeout(that.time)
    //   }
    //   that.time = setTimeout(function () {
    // 放你的逻辑代码
    //     that.time = null;
    //   }, 1000)
    // },

    // 退出登录
    quit() {
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      this.userInfo = {}
      this.userInfoIss = false
      this.$message({ message: '退出登录成功', type: 'success' });
      // this.$router.push('/')
      this.$router.push({
        name: 'Home',
      }, () => { })
    },
  },
  created() {
    this.$bus.$on('webMessage1', msg => {
      if (msg) {
        let webMessage = JSON.parse(localStorage.getItem('webMessage'))
        this.webLogo = webMessage.website_logo
      } else {
        this.webLogo = require('../assets/logo.png')
      }
    })

    this.$bus.$on('webMessage2', msg => {
      if (msg) {
        let webMessage = JSON.parse(localStorage.getItem('webMessage'))
        this.wxLogo = webMessage.website_wechat
      }
    })

    this.$bus.$on('webMessage3', msg => {
      if (msg) {
        let webMessage = JSON.parse(localStorage.getItem('webMessage'))
        this.wxExperience = webMessage.wechat_miniprogram
      }
    })

    let webMessage = JSON.parse(localStorage.getItem('webMessage'))
    if (webMessage.website_logo !== '') {
      this.webLogo = webMessage.website_logo
    } else {
      this.webLogo = require('../assets/logo.png')
    }
    if (webMessage.website_wechat !== '') {
      this.wxLogo = webMessage.website_wechat
    }
    if (webMessage.wechat_miniprogram !== '') {
      this.wxExperience = webMessage.wechat_miniprogram
    }


 
    this.$bus.$on('demand', msg => {
      this.userShow = true
    })
    // 判断是否登录
    if (localStorage.getItem('token') !== null && localStorage.getItem('userInfo') !== null) {
      this.userInfoIss = true
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.editInfo.avatar = this.userInfo.avatar
      this.editInfo.name = this.userInfo.store_name

    }else{
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      this.$forceUpdate();
    }
    // if (this.userInfo.id) {
    //   this.userInfoIss = true
    // }
    // window.myData = this;//将变量全局化,这样浏览器可以查看当前data是什么情况::myData._data
  },
}
</script>

<style lang="less" scoped>
.myButton {
  background: var(--theme_bg_color);
}

.content {
  padding: 0;
  display: flex;
  justify-content: space-between;

  // line-height: 67.5px;
  .logo {
    .logoImg {
      width: 270px;
      height: 67.5px;
      display: block;
    }
  }

  .userOperationArea {
    display: flex;
    cursor: pointer;
    line-height: 0;

    .customerService {
      img {
        margin-top: 25px;
        width: 24px;
        height: 22px;
      }
    }

    .activationCode {
      cursor: pointer;

      .activeBox {
        display: flex;
        height: 40px;
        margin-top: 16.25px;
        background: #F3F4FB;
        margin-left: 15px;
        border-radius: 9px;
        color: #3D3D3D;
        padding: 0 10px;
        font-size: 14px;

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          white-space: nowrap;
        }

        .img {
          margin-right: 5px;

          .image {
            width: 24px;
            height: 24px;
          }

        }
      }

      .demo-input-suffix {
        display: flex;
        white-space: nowrap;
      }

      /deep/.el-dialog {
        border-radius: 20px !important;
      }

      /deep/.el-dialog__header {
        line-height: 0;

      }
    }

    .openMembership {
      cursor: pointer;
      display: flex;
      height: 40px;
      margin-top: 16.25px;
      background: #FFECD4;
      margin-left: 15px;
      border-radius: 9px;
      color: #3D3D3D;
      padding: 0 10px;
      font-size: 14px;

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        white-space: nowrap;
      }

      .img {
        margin-right: 5px;

        .image {
          width: 24px;
          height: 20px;
        }

      }
    }

    .userInfo {
      cursor: pointer;
      margin: 0 20px;
      margin-top: 16.25px;

      img {
        width: 36px;
        height: 36px;
        border-radius: 50px;
      }
    }

    .more {
      cursor: pointer;
      border-radius: 9px;
      margin-top: 16.25px;
      padding: 0 10px;
      height: 40px;

      display: flex;
      background: #DEE8FC;

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .middle {
        color: #D8D8D8;
      }

      .image {
        width: 24px;
        height: 24px;
        margin: 0 10px;
      }
    }
  }
}

.el-popper {
  .customerServiceBox {
    .contactUs {
      text-align: center;
    }

    .contactUsImg {
      margin-top: 10px;
      text-align: center;

      img {
        width: 120px;
        height: 120px;
      }
    }

    .cooperation {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      padding: 10px 5px;
      border-radius: 10px;
    }

    .cooperation:hover {
      background-color: #EAECFF;

    }

    .assist {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      padding: 10px 5px;
      border-radius: 10px;
    }

    .assist:hover {
      background-color: #EAECFF;

    }


  }

  .experience {
    text-align: center;

    .wechatID {
      width: 150px;
      height: 150px;
    }
  }

  .user_avatar_name_phone {
    display: flex;

    .user_avatar {
      img {
        width: 65px;
        height: 65px;
        border-radius: 50px;
        cursor: pointer;
      }
    }

    .user_name_phone {
      margin-left: 10px;

      .user_name {
        display: flex;
        line-height: 40px;
        cursor: pointer;

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        img {
          width: 15px;
          height: 15px;
          margin-left: 5px;
        }
      }

      .user_phone {
        line-height: 40px;
      }
    }
  }

  .balance {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .right {
      color: #3977F3;
      cursor: pointer;
    }
  }

  .purchaseRecord {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 5px;
    border-radius: 10px;
  }

  .purchaseRecord:hover {
    background-color: #EAECFF;

  }

  .quit {
    cursor: pointer;
    padding: 10px 5px;
    border-radius: 10px;
  }

  .quit:hover {
    background-color: #EAECFF;
  }


}

/deep/.pointPurchaseShow {
  background: linear-gradient(180deg, #ECF1FF 0%, #FFFFFF 100%), #FFFFFF !important;
}

/deep/.consumptionRecordShow {
  border-radius: 10px;

  .consumpContent {

    height: 500PX;
    overflow-y: auto;
    /* 出现垂直滚动条 */
  }

  .paging {
    display: flex;
    justify-content: center;
  }
}
</style>
