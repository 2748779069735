<template>
    <div class="content">
        <div style="padding:0 20px ;">
            <Header></Header>
        </div>
        <div class="header">
            <span class="title" @click="goBack">
                <i style="cursor: pointer;" class="el-icon-arrow-left"></i>{{ modeData.name }}
            </span>

        </div>
        <div class="cloneBox">
            <div class="cloneBox_left">
                <div class="Boxtitle">形象克隆内容</div>
                <!-- 克隆方式 -->
                <div class="cloneMode">
                    <div class="title"><span>*</span>克隆方式</div>
                    <div class="mode">
                        <div class="item">
                            <div class="img"><img :src="modeData.img" alt=""></div>
                            <div class="name_introduce_frequency">
                                <div class="name">{{ modeData.name }}</div>
                                <div class="introduce">{{ modeData.title }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 形象名称 -->
                <div class="cloneName">
                    <div class="title"><span>*</span>形象名称</div>
                    <div><el-input type="text" placeholder="请输入形象名称" v-model="cloneNameInput" maxlength="10"
                            show-word-limit>
                        </el-input></div>
                </div>
                <!-- 底板视频 -->
                <div class="backplaneVideo">
                    <div class="title"><span>*</span>形象视频</div>

                    <div :style="{ 'display': (vPath == '' ? 'block' : 'none') }">
                        <div class="uploadBox">
                            <div class="upload">
                                <videoUpload :showProgress="showProgress" ref="videoUpload" @showP="showP"
                                    @videoPath="videoPath" />
                            </div>
                            <div class="referenceText">
                                <div class="r_title">文本内容请参考右侧示例文件 </div>
                                <div class="r_text">格式：MP4,MOV</div>
                                <div class="r_text">时长：10~300s </div>
                                <div class="r_text">文件大小：不超过500mb</div>
                                <div class="r_text">帧率：30FPS</div>
                            </div>
                        </div>

                    </div>

                    <div :style="{ 'display': (vPath !== '' ? 'block' : 'none') }">
                        <div class="video">
                            <div class="videoBox"><video :src="vPath" controls></video></div>
                            <div class="reupload" @click="reupload">
                                <div class="text">重新上传视频</div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div class="cloneBox_right">
                <div class="right_title">示例视频</div>
                <div class="right_box">
                    <div class="box">
                        <div class="back" :style="{ backgroundImage: 'url(' + heha + ')', backgroundSize: '100%' }">
                        </div>
                        <div class="miImg"><img :src="heha" alt=""></div>
                    </div>
                </div>
                <div class="right_text">
                    <div class="right_text_box">
                        <div class="item" v-for="(item, index) in example" :key="index">
                            <div><img src="../../assets/zhengque.png" alt=""></div>
                            <div>{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- 提交 -->
        <div class="submit_box">
            <div class="submit_cancel">
                <div><el-button type="primary" @click="submit">提 交</el-button></div>
                <div><el-button @click="cancel">取 消</el-button></div>
            </div>
            <div class="agreement">
                <el-checkbox v-model="signinChecked">我确认我拥有上传形象视频的所有必要权力，我保证不会用克隆形象创作违法内容，并且会严格遵守
                </el-checkbox><span @click.stop="openAgreement">《数字人克隆服务协议》</span>
            </div>
        </div>


        <el-dialog title="数字人克隆服务协议" custom-class="agreementShow" :visible.sync="agreementShow" width="600px">
            <div class="text">
                <ImageCloningProtocol />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="consentAgreement">阅读并同意</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Header from '@/components/Header.vue'
import videoUpload from '../../../components/videoUpload.vue'
import { cloneImage } from '../../../api/api'
import ImageCloningProtocol from '../../../components/agreement/ImageCloning.vue'
export default {
    components: {
        Header,
        videoUpload,
        ImageCloningProtocol
    },
    data() {
        return {
            heha: 'https://cdn.oouu.cc/virtuai/moxing.png',//示例照片
            cloneNameInput: '',//形象名称
            modeData: {},
            example: [
                { name: '提交10~300s的原视频素材' },
                { name: '使用高分辨率相机' },
                { name: '在光线充足、安静的环境中录制' },
                { name: '恰当的手势，并不高过胸部' }

            ],
            signinChecked: false,
            vPath: '',//上传OSS返回的链接
            showProgress: false,

            moduleV1: {
                name: 'V1克隆',
                title: '价格优惠，合成速度较慢，基本还原口型特征，可在视频、对话、直播中使用 。',
                img: require('../../assets/v1.png'),
                type: 1
            },
            moduleV2: {
                name: 'V2克隆',
                title: '无限并发，合成速度快，几分钟即可完成训练，还原大部分神情特征，可用于视频、对话、直播中使用 。',
                img: require('../../assets/v2.png'),
                type: 2
            },
            // moduleV5: {
            //     name: 'V5克隆',
            //     title: '合成速度快，几分钟即可完成训练，一比一还原人物神情特征，可用于视频、对话、直播中使用 。',
            //     img: require('../../assets/v5.png'),
            //     type: 5
            // },
            tiem: null,
            fullscreenLoading: false,//加载
            type: {},
            agreementShow: false,//协议弹框

        }
    },

    methods: {
        goBack() {
            this.$router.back()
        },
        videoPath(e) {
            this.vPath = e
        },
        showP(e) {
            this.showProgress = e
        },
        reupload() {//重新上传
            this.vPath = ''
            this.showProgress = false
            this.$refs.videoUpload.$refs.videoUpload.clearFiles(); // 方法2:直接调用
        },
        // 提交
        submit() {
            let that = this
            if (that.time) {
                clearTimeout(that.time)
            }
            that.time = setTimeout(function () {
                let regex = /^[a-zA-Z0-9\u4e00-\u9fa5\(\)\-\|\.\[\]《》@]*$/;
                let str1 = that.cloneNameInput
                if (!regex.test(str1)) return that.$message.error('名称只能使用以下特殊字符 . / | ( ) [ ] 《 》 @');


                if (!that.cloneNameInput) return that.$message({ message: '请输入形象名称', type: 'error' });
                if (!that.vPath) return that.$message({ message: '请上传底板视频', type: 'error' });
                if (!that.signinChecked) return that.$message({ message: '请阅读并同意克隆协议', type: 'error' });
                const loading = that.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });

                let data = {
                    name: that.cloneNameInput,
                    source_url: that.vPath,
                    line_id: that.modeData.type,
                }
                cloneImage(data).then(res => {
                    if (res.data.code == 10000) {
                        loading.close();
                        that.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        that.$router.go(-1)//成功回退上一页刷新
                    } else {
                        loading.close();
                    }
                })
                that.time = null;
            }, 1000)
        },
        openAgreement() {
            this.agreementShow = true
        },
        // 同意协议
        consentAgreement() {
            this.signinChecked = true
            this.agreementShow = false
        },


        cancel() {
            this.$router.go(-1)
        },

        // typeLocalStorage() {
        //     let type = localStorage.getItem('type')
        //     if (type === 'v1') { this.modeData = this.moduleV1 }
        //     if (type === 'v2') { this.modeData = this.moduleV2 }
        //     if (type === 'v5') { this.modeData = this.moduleV5 }
        // }
    },
    // mounted() {
    //     window.addEventListener('beforeunload', (e) => localStorage.setItem('type', this.type));
    //     window.addEventListener('unload', this.typeLocalStorage());
    // },
    created() {
        if (this.$route.query.type) {
            var type = this.$route.query.type
        }
        // else {
        //     var type = localStorage.getItem('type')
        // }
        this.type = type
        if (type === 'v1') { this.modeData = this.moduleV1 }
        if (type === 'v2') { this.modeData = this.moduleV2 }
        if (type === 'v5') { this.modeData = this.moduleV5 }
    },
}
</script>
<style lang="less" scoped>
.content {
    .header {
        padding: 0 20px;
        height: 50px;
        background: rgba(146, 186, 255, 0.4);
        line-height: 50px;

        .title {
            cursor: pointer;
            font-weight: bold;

            i {
                margin-right: 10px;
                font-weight: bold;
                color: #000;
            }
        }

    }

    .cloneBox {
        margin: 20px 25px 20px 25px;
        padding: 0 15px 0 15px;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        height: 685px;

        .cloneBox_left {
            border-right: 1px solid #D8D8D8;
            width: 60%;

            .Boxtitle {
                color: #3D3D3D;
                line-height: 50px;
            }

            .cloneMode {
                .title {
                    color: #3D3D3D;
                    line-height: 30px;
                    font-size: 14px;

                    span {
                        color: red;
                    }
                }

                .mode {
                    display: flex;

                    .item {
                        width: 350px;
                        background-color: #EBEEF9;
                        padding: 10px;
                        border-radius: 10px;
                        display: flex;
                        margin-right: 20px;
                        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.16);

                        /*悬停变小手的属性*/
                        .img {
                            img {
                                width: 103px;
                                height: 137px;
                            }
                        }

                        .name_introduce_frequency {
                            margin-left: 10px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            padding: 10px 0;

                            .name {}

                            .introduce {
                                font-size: 12px;
                            }

                            .frequency {
                                display: flex;
                                border: 1px solid #4589F1;
                                border-radius: 5px;
                                padding: 4px 8px;
                                font-size: 14px;

                                .left {
                                    color: red;
                                }

                                .mid {
                                    color: red;
                                }

                                .right {
                                    text-align: center;
                                    color: #4589F1;
                                }
                            }
                        }

                    }
                }
            }

            .cloneName {
                margin-top: 20px;
                width: 280px;

                .title {
                    color: #3D3D3D;
                    line-height: 30px;
                    font-size: 14px;

                    span {
                        color: red;
                    }
                }
            }

            .backplaneVideo {
                margin-top: 20px;
                padding-bottom: 20px;

                .title {
                    color: #3D3D3D;
                    line-height: 30px;
                    font-size: 14px;

                    span {
                        color: red;
                    }
                }

                .uploadBox {
                    display: flex;

                    .upload_but {
                        width: 200px;
                        height: 200px;
                        border: 1px solid #ccc;
                        border-radius: 10px;

                        div {
                            display: flex;
                            justify-content: center;
                        }

                        .el-icon-upload {
                            text-align: center;
                            font-size: 50px;
                            color: #409EFF;
                        }

                        .el-upload__text {
                            font-size: 12px;
                        }
                    }

                    .referenceText {
                        margin-left: 20px;

                        .r_text {
                            color: #7F7F7F;
                            font-size: 12px;
                            line-height: 40px;
                        }
                    }
                }

                .video {


                    .videoBox {


                        video {
                            width: 521px;
                            height: 233px;
                        }
                    }

                    .reupload {


                        .text {
                            cursor: pointer;
                            /*悬停变小手的属性*/
                            width: 521px;
                            height: 36px;
                            line-height: 36px;
                            text-align: center;
                            color: #3E7AFE;
                            font-size: 20px;
                            background-color: #fff;
                            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
                            border-radius: 0px 0px 5px 5px;
                        }

                    }
                }
            }
        }

        .cloneBox_right {
            width: 40%;

            .right_title {
                color: #3D3D3D;
                line-height: 50px;
                padding: 0 20px;
            }

            .right_box {
                display: flex;
                justify-content: center;

                .box {
                    margin-top: 30px;
                    width: 423px;
                    height: 237px;
                    border-radius: 10px;
                    position: relative;
                    margin-right: 30px;

                    .back {
                        width: 423px;
                        height: 237px;
                        -webkit-filter: blur(5px);
                        -moz-filter: blur(5px);
                        -o-filter: blur(5px);
                        -ms-filter: blur(5px);
                        filter: blur(5px);
                    }

                    .miImg {
                        position: absolute;
                        top: 0;
                        left: 145px;

                        img {
                            display: block;
                            width: 133.5px;
                            height: 237px;

                        }
                    }
                }

            }

            .right_text {
                display: flex;
                justify-content: center;

                .right_text_box {
                    margin-top: 40px;

                    .item {
                        display: flex;
                        line-height: 40px;

                        img {
                            width: 15px;
                            height: 15px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    .submit_box {
        position: relative;
        left: 0;
        bottom: 0;
        height: 80px;
        background: #FFFFFF;
        width: 100%;
        display: flex;

        .submit_cancel {
            display: flex;
            height: 100%;

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 30px;
            }
        }

        .agreement {
            margin-left: 100px;
            line-height: 80px;
            color: #3D3D3D;
        }

        span {
            font-size: 14px;
            color: #3977F3;
            cursor: pointer;
        }
    }
}

/deep/.agreementShow {
    .text {
        overflow-y: auto;
        /* 出现垂直滚动条 */
        height: 500px;
    }

    border-radius: 10px;
}

/deep/.agreementShow::-webkit-scrollbar {
    .text::-webkit-scrollbar {
        display: none;
    }
}
</style>