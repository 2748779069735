<template>
    <!-- 形象抽屉 -->
    <div class="imageDrawer">
        <div class="image_box">
            <div class="tab-header">
                <div class="tab-background" :style="backgroundStyleImage"></div>
                <div class="tab-slider" :style="sliderStyleImage"></div>
                <button v-for="(tab, index) in drivensImage" :key="tab.id" @click="selectTabDrawerImage(index)"
                    :class="{ active: drivenInImage === tab.id }">
                    {{ tab.title }}
                </button>
            </div>
        </div>
        <div class="search_paging">
            <div class="search">
                <el-input placeholder="请输入内容" @change="search" prefix-icon="el-icon-search" v-model="myImageValue">
                </el-input>
            </div>
            <div class="paging" v-if="total > 12">
                <el-pagination layout="total, prev, pager, next" :current-page="page" :page-size="12" :total="total"
                    @current-change="handlePageChange" />
            </div>
        </div>
        <!-- 我的形象 -->
        <div class="myImage" v-if="activeImageIndex == 0">
            <div class="myImageList">
                <div :class="selectVideoIndex == index ? 'activeItem' : 'item'" @click="selectVideo(item, index)"
                    v-for="(item, index) in list" :key="index">
                    <div class="back"
                        :style="{ backgroundImage: 'url(' + item.cover_url + ')', backgroundSize: '100%' }">
                    </div>
                    <div class="miImg">
                        <div class="item_img">
                            <img class="item_img_ab" :src="item.cover_url" alt="">
                        </div>
                    </div>
                    <div class="itemName">{{ item.name }}</div>
                </div>
            </div>
            <div v-if="list.length == ''">
                <noData />
            </div>
        </div>
        <!-- 公共形象 -->
        <div class="comImage" v-if="activeImageIndex == 1">
            <div class="myImageList comImageList">
                <div class="item" @click="selectVideo(item, index)"
                    :class="selectVideoIndex == index ? 'activeItem' : ''" v-for="(item, index) in list" :key="index">
                    <div class="back"
                        :style="{ backgroundImage: 'url(' + item.cover_url + ')', backgroundSize: '100%' }">
                    </div>
                    <div class="miImg">
                        <div class="item_img">
                            <img class="item_img_ab" :src="item.cover_url" alt="">
                        </div>
                    </div>
                    <div class="itemName">{{ item.name }}</div>
                </div>
            </div>
            <div v-if="list.length == ''">
                <noData />
            </div>
        </div>
        <div class="verify_cancel">
            <div class="verify" @click="playVideoVerify">
                确认
            </div>
        </div>
    </div>
</template>
<script>
import noData from '../../../components/noData.vue';
import { getMyModelList, getComModelList } from '../../../api/api'
export default {
    props: {
        type: {
            type: Number,
            default: 0
        }
    },
    components: {
        noData
    },
    data() {
        return {
            direction: 'rtl',//抽屉从右打开
            drivenInImage: 'myImage',
            activeImageIndex: 0,
            drivensImage: [
                { id: 'myImage', title: '我的形象' },
                { id: 'comImage', title: '公共形象' }
            ],
            selectVideoIndex: '-1',//选中的形象 

            myImageValue: '',//搜索
            list: [],//形象列表
            page: 1,
            total: 0,

            selectImage: {},//选中的形象

            fullscreenLoading: false//加载

        }
    },
    computed: {
        sliderStyle() {
            return {
                transform: `translateX(${this.activeIndex * 100}%)`
            }
        },
        backgroundStyle() {
            return {
                transform: `translateX(${this.activeIndex * 100}%)`
            }
        },

        sliderStyleImage() {
            return {
                transform: `translateX(${this.activeImageIndex * 100}%)`
            }
        },
        backgroundStyleImage() {
            return {
                transform: `translateX(${this.activeImageIndex * 100}%)`
            }
        },
    },
    methods: {
        selectTabDrawerImage(index) {
            this.page = 1
            this.activeImageIndex = index
            this.selectVideoIndex = '-1'
            this.selectImage = {}
            this.myImageValue = ''
            if (index == 0) {
                this.getMyModelList()
            } else if (index == 1) {
                this.getComModelList()
            }
        },
        // 选择形象
        selectVideo(item, index) {
            this.selectVideoIndex = index
            this.selectImage = item
        },
        handlePageChange(currentPage) {
            this.page = currentPage
            this.getMyModelList()
            // 在此刷新数据
        },
        // 确认
        playVideoVerify() {
            if (this.selectImage.id == undefined) return this.$message.error('请选择形象');
            this.$emit('selectImageItem', this.selectImage)
            this.$emit('imageDra', false)
        },
        // 搜索
        search() {
            this.page = 1
            if (this.activeImageIndex == 0) {
                this.getMyModelList()
            }
            if (this.activeImageIndex == 1) {
                this.getComModelList()
            }

        },
        getMyModelList() {// 获取我的形象
            const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });
            let params = {
                page: this.page,
                page_size: 12,
                name: this.myImageValue,
                line_id: this.type
            }
            getMyModelList(params).then(res => {
                if (res.data.code == 10000) {
                    this.list = res.data.data
                    this.total = res.data.total
                    loading.close();
                } else {
                    loading.close();
                }
            })
        },
        //获取公共形象
        getComModelList() {
            const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)' });

            let params = {
                page: this.page,
                page_size: 12,
                name: this.myImageValue,
                line_id: this.type
            }
            getComModelList(params).then(res => {
                if (res.data.code == 10000) {
                    this.list = res.data.data
                    this.total = res.data.total
                    loading.close();
                } else {
                    loading.close();
                }
            })
        },

    },
    created() {
        this.getMyModelList()
    },
    watch: {
        type(newVal, oldVal) {
            this.page = 1
            this.selectVideoIndex = -1
            this.getMyModelList()
            this.getComModelList()
        }
    }
}
</script>
<style lang="less" scoped>
.imageDrawer {
    /deep/.rtl {
        width: 1008px !important;
        padding: 0 20px;
    }

    .image_box {
        .tab-header {
            display: flex;
            background-color: #D8D8D8;
            position: relative;
            overflow: hidden;
            border-radius: 50px;
            width: 1130px;
            height: 70px;


            .tab-background {
                margin: 5px 10px;
                position: absolute;
                top: 0;
                left: 0;
                height: 60px;
                width: 555px;
                background-color: #fff;
                transition: transform 0.3s ease;
                border-radius: 50px;

                .tab-slider {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 2px;
                    width: 50%;
                    background-color: #007bff;
                    transition: transform 0.3s ease;
                }
            }

            button {
                flex: 1;
                background: none;
                border: none;
                padding: 15px 30px;
                font-size: 16px;
                cursor: pointer;
                transition: color 0.3s ease;
                z-index: 1;
                position: relative;
            }

            .tab-header button.active {
                font-weight: bold;
                color: #007bff;
                border-radius: 50px;
            }
        }
    }

    .search_paging {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;

        .search {
            width: 200px;
        }
    }

    .myImage {
        position: relative;

        .myImageList {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 100px;

            .activeItem {
                border: 1px solid #3E7AFE;
                border-radius: 10px;
                width: 320px;
                height: 208px;
                margin-top: 15px;
                margin-right: 60px;
                position: relative;
                cursor: pointer;

                .back {
                    width: 322px;
                    height: 181px;
                    -webkit-filter: blur(2px);
                    -moz-filter: blur(2px);
                    -o-filter: blur(2px);
                    -ms-filter: blur(2px);
                    filter: blur(2px);
                }

                .miImg {
                    position: absolute;
                    top: 0;
                    left: 110.5px;

                    .item_img:hover {

                        .Play_back {
                            img {
                                display: block;

                            }
                        }
                    }

                    .item_img {
                        position: relative;

                        .item_img_ab {
                            display: block;
                            width: 101px;
                            height: 179px;
                            margin-top: 1px;
                        }
                    }
                }

                .itemName {
                    text-align: center;
                }

            }

            .item {
                width: 322px;
                margin-top: 15px;
                margin-right: 60px;
                position: relative;
                cursor: pointer;
                height: 210px;

                .back {
                    width: 322px;
                    height: 181px;
                    -webkit-filter: blur(2px);
                    -moz-filter: blur(2px);
                    -o-filter: blur(2px);
                    -ms-filter: blur(2px);
                    filter: blur(2px);
                }

                .miImg {
                    position: absolute;
                    top: 0;
                    left: 110.5px;

                    .item_img:hover {

                        .Play_back {
                            img {
                                display: block;

                            }
                        }
                    }

                    .item_img {
                        position: relative;

                        .item_img_ab {
                            display: block;
                            width: 101px;
                            height: 179px;
                            margin-top: 1px;
                        }
                    }
                }

                .itemName {
                    text-align: center;
                }
            }
        }

        .paging {
            background-color: #fff;
            position: sticky;
            bottom: 0;
            left: 0;
            height: 50px;
            display: flex;
            justify-content: center;
        }


    }

    .comImage {
        .myImageList {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 100px;

            .activeItem {
                border: 1px solid #3E7AFE;
                border-radius: 10px;
                width: 320px;
                height: 208px;
                margin-top: 15px;
                margin-right: 60px;
                position: relative;
                cursor: pointer;

                .back {
                    width: 322px;
                    height: 181px;
                    -webkit-filter: blur(2px);
                    -moz-filter: blur(2px);
                    -o-filter: blur(2px);
                    -ms-filter: blur(2px);
                    filter: blur(2px);
                }

                .miImg {
                    position: absolute;
                    top: 0;
                    left: 110.5px;

                    .item_img:hover {

                        .Play_back {
                            img {
                                display: block;

                            }
                        }
                    }

                    .item_img {
                        position: relative;

                        .item_img_ab {
                            display: block;
                            width: 101px;
                            height: 179px;
                            margin-top: 1px;
                        }
                    }
                }

                .itemName {
                    text-align: center;
                }

            }

            .item {
                width: 322px;
                height: 210px;
                margin-top: 15px;
                margin-right: 60px;
                position: relative;
                cursor: pointer;

                .back {
                    width: 322px;
                    height: 181px;
                    -webkit-filter: blur(2px);
                    -moz-filter: blur(2px);
                    -o-filter: blur(2px);
                    -ms-filter: blur(2px);
                    filter: blur(2px);
                }

                .miImg {
                    position: absolute;
                    top: 0;
                    left: 110.5px;

                    .item_img:hover {

                        .Play_back {
                            img {
                                display: block;

                            }
                        }
                    }

                    .item_img {
                        position: relative;

                        .item_img_ab {
                            display: block;
                            width: 101px;
                            height: 179px;
                            margin-top: 1px;
                        }
                    }
                }

                .itemName {
                    text-align: center;
                }
            }
        }
    }


    .verify_cancel {
        position: sticky;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: right;
        background: #fff;
        padding: 10px 20px;

        div {
            cursor: pointer;
            /*悬停变小手的属性*/
            width: 167px;
            height: 55px;
            border-radius: 10px;
            line-height: 55px;
            text-align: center;
            font-size: 24px;
            margin-right: 15px;
            background: #3E7AFE;
            color: #fff;
        }
    }
}
</style>